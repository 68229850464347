<template>
  <div>
    <v-app>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="form.date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-trigger-modal-date"
                v-model="form.date"
              >
              </b-form-input>
            </template>

            <v-date-picker v-if="modal2" locale="id" v-model="form.date">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Name input -->
        <b-form-group
          id="input-group-po-no"
          label="No PO:"
          label-for="input-po-no"
        >
          <b-form-input
            id="input-po-no"
            v-model="form.po_no"
            placeholder="No PO"
          ></b-form-input>
          <small class="text-danger">{{ error.po_no }}</small>
        </b-form-group>

        <!-- Name input -->
        <!-- Input Company -->
        <b-form-group id="input-group-vendor">
          <label for="input-vendor">Kepada:</label>
          <treeselect
            v-model="form.vendor_id"
            :multiple="false"
            :options="vendors"
            @select="setVendor"
          />
          <small class="text-danger">{{ error.vendor_name }}</small>
        </b-form-group>

        <!-- Input Opening Sentence -->
        <b-form-group id="input-group-opening-sentence">
          <label for="input-opening-sentence"
            >Kalimat Pembuka: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-opening-sentence"
            v-model="form.opening_sentence"
            rows="3"
            placeholder="Kalimat Pembuka"
            @keyup="setOpeningSentence"
          ></b-form-textarea>
          <small class="text-danger">{{ error.opening_sentence }}</small>
        </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine">Item:</label>
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_name"
              placeholder="Pilih Item"
              readonly
              @click="$bvModal.show('modal-medicine')"
              style="border-right: none"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicine"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.items }}</small>
        </b-form-group>

        <div class="bg-light">
          <table class="table">
            <tr>
              <th>No</th>
              <th>Items</th>
              <th>Quantity</th>
              <th>Price/case(IDR)</th>
              <th>Total Prices</th>
              <th>Aksi</th>
            </tr>
            <tr v-for="(mdc, index) in medicine_collection" :key="mdc.id">
              <td>{{ index + 1 }}</td>
              <td>{{ mdc.item }}</td>
              <td>{{ mdc.quantity }}</td>
              <td>{{ mdc.price }}</td>
              <td>{{ mdc.total_price }}</td>
              <td>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus Item"
                  type="button"
                  @click="removeItem(mdc.item)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </td>
            </tr>
          </table>
        </div>

        <b-modal
          ref="my-modal"
          hide-footer
          title="Form Item"
          @hide="hideModalCancel"
        >
          <b-form @submit.stop.prevent="medicineFormOnsubmit">
            <!-- <div class="d-block text-center"> -->
            <b-form-group
              id="input-group-item"
              label="Nama:"
              label-for="input-item"
            >
              <b-form-input
                id="input-item"
                v-model="medicine_collection_form.item"
                placeholder="Item"
                readonly
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-quantity"
              label="Kuantitas:"
              label-for="input-quantity"
            >
              <b-form-input
                id="input-quantity"
                v-model="medicine_collection_form.quantity"
                placeholder="Kuantitas"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-price"
              label="Harga:"
              label-for="input-price"
            >
              <b-form-input
                id="input-price"
                v-model="formatedSalesPrice"
                placeholder="Harga"
                inputmode="numeric"
                required
              ></b-form-input>
            </b-form-group>
            <!-- </div> -->
            <!-- Submit & Cancel button -->
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="hideModalCancel"
            >
              Cancel
            </b-button>
          </b-form>
        </b-modal>

        <!-- Input Discount -->
        <b-form-group
          id="input-group-discount"
          label="Diskon(%):"
          label-for="input-discount"
        >
          <b-form-input
            id="input-discount"
            v-model="form.discount"
            placeholder="Diskon"
          >
          </b-form-input>
          <small class="text-danger">{{ error.discount }}</small>
        </b-form-group>

        <!-- Input PPN -->
        <b-form-group
          id="input-group-ppn"
          label="PPN(%):"
          label-for="input-ppn"
        >
          <b-form-input id="input-ppn" v-model="form.ppn" placeholder="PPN">
          </b-form-input>
          <small class="text-danger">{{ error.ppn }}</small>
        </b-form-group>

        <!-- Input Closing Sentence -->
        <b-form-group id="input-group-closing-sentence">
          <label for="input-closing-sentence"
            >Kalimat Penutup: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-closing-sentence"
            v-model="form.closing_sentence"
            rows="3"
            placeholder="Kalimat Penutup"
          ></b-form-textarea>
          <small class="text-danger">{{ error.closing_sentence }}</small>
        </b-form-group>

        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/purchase-orders-pharmacy')"
        >
          Batal
        </b-button>
      </b-form>

      <ModalMedicine
        @chosenMedicine="setPrescription"
        :selectedItem="medicine_collection"
        :checkbox="true"
      />
    </v-app>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import ModalMedicine from "@/component/general/ModalMedicine.vue";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
    ModalMedicine,
  },

  data() {
    return {
      // Display Data
      display: {
        medicine_name: "",
      },
      modal2: false,
      // Error
      error: {
        date: "",
        po_no: "",
        vendor_id: "",
        vendor_name: "",
        items: "",
        discount: "",
        ppn: "",
        opening_sentence: "",
        closing_sentence: "",
      },
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      tester: "",
      medicine_active: [],
      medicine_collection: [],
      medicine_collection_str: [],
      medicine_collection_form: {
        id: "",
        item: "",
        quantity: "",
        price: "",
        total_price: "",
      },
      // other
      vendors: [],
      opening_sentence_write: false,
      medicine_collection_submit: false,
      btnClearMedicineClass: "bg-white",
    };
  },

  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    setOpeningSentence() {
      this.opening_sentence_write = true;
    },

    getMedicineActive() {
      ApiService.get("pharmacy-purchase-orders/" + this.$route.params.id).then(
        (response) => {
          this.medicine_active = JSON.parse(response.data.data.items);
          this.medicine_collection = JSON.parse(response.data.data.items);

          this.displayMedicineName(JSON.parse(response.data.data.items));

          // let a
          // for (a = 0; this.medicine_active.length; a++) {
          //   this.medicine_id.push(this.medicine_active[a].id)
          //   console.log("med_actv", this.medicine_active)
          //   console.log("med_actv[a]", this.medicine_active[a].id)
          //   console.log("loop")
          //   console.log("medicine_id", this.medicine_id)
          // }
        }
      );
    },

    async getVendorOption() {
      let response = await module.setTreeSelect("vendors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vendors = response.data;
        this.vendors.unshift({
          label: "Pilih Supplier",
          id: "",
          isDisabled: true,
        });
      }
    },

    setVendor(evt) {
      this.form.vendor_id = evt.id;
      this.form.vendor_name = evt.label;
      // if(this.opening_sentence_write == false){
      //   this.form.opening_sentence = `Sehubungan dengan surat penawaran harga (quotation) ${evt.label}, maka kami ingin memesan produk sebagai berikut :`
      // }
      // this.form.opening_sentence = `Sehubungan dengan surat penawaran harga (quotation) ${evt.label}, maka kami ingin memesan produk sebagai berikut :`
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },

    removeItem($item) {
      let a;
      for (a = 0; a < this.medicine_collection.length; a++) {
        if (this.medicine_collection[a].item == $item) {
          this.medicine_collection.splice(a, 1);
        }
      }
      this.displayMedicineName(this.medicine_collection);
    },

    async setPrescription(value) {
      if (this.medicine_collection.length > 0) {
        let a;
        for (a = 0; a < this.medicine_collection.length; a++) {
          if (this.medicine_collection[a].id == value.id) {
            this.medicine_collection.splice(a, 1);
            if (this.medicine_collection.length == 0) {
              this.display.medicine_name = "";
              break;
            } else if (this.medicine_collection.length > 0) {
              this.displayMedicineName(this.medicine_collection);
              break;
            }
            break;
          } else if (a == this.medicine_collection.length - 1) {
            this.$bvModal.hide("modal-medicine");
            await this.medicine_id;
            this.medicine_id = value.id;
            // this.medicine_collection_form.id = "";
            // this.medicine_collection_form.name = "";
            this.medicine_collection_form.quantity = "";
            this.medicine_collection_form.item = "";
            this.medicine_collection_form.price = "";
            this.medicine_collection_form.total_price = "";
            this.setMedicine(value.id);
          }
        }
      } else {
        this.$bvModal.hide("modal-medicine");
        await this.medicine_id;
        this.medicine_id = value.id;
        // this.medicine_collection_form.id = "";
        // this.medicine_collection_form.name = "";
        this.medicine_collection_form.quantity = "";
        this.medicine_collection_form.item = "";
        this.medicine_collection_form.price = "";
        this.medicine_collection_form.total_price = "";
        this.setMedicine(value.id);
      }
    },

    setMedicine(id) {
      // console.log("alhamdulillah sampai sini");
      ApiService.get("medicines/" + id).then((response) => {
        this.medicine_collection_form.id = id;
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.medicine_collection_form.item = response.data.data.name;
        this.$refs["my-modal"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.item;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    resetMedicine() {
      this.medicine_collection.splice(0, this.medicine_collection.length);
      this.display.medicine_name = "";
    },

    medicineFormOnsubmit() {
      this.medicine_collection_submit = true;
      let price = parseInt(this.medicine_collection_form.price);
      // this.medicine_collection_form.total_price = this.medicine_collection_form.quantity * this.medicine_collection_form.price
      // console.log("yang akan di input", this.medicine_collection_form);
      // this.medicine_collection.push(this.medicine_collection_form);
      this.medicine_collection_form.total_price =
        this.medicine_collection_form.quantity *
        this.medicine_collection_form.price.replace(".", "");
      this.medicine_collection_form.total_price = parseInt(
        this.medicine_collection_form.total_price
      ).toLocaleString("id-ID");
      let clone = { ...this.medicine_collection_form };
      this.medicine_collection.push(clone);

      this.displayMedicineName(this.medicine_collection);
      this.hideModal();
    },

    async formOnsubmit() {
      if (this.medicine_collection.length > 0) {
        this.medicine_collection_str = await JSON.stringify(
          this.medicine_collection
        );
        this.form.items = await this.medicine_collection_str;
        // Make Request
        let response = await module.submit(this.form, this.route);
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          this.$router.push("/purchase-orders-pharmacy");
        }
      } else if (this.medicine_collection.length == 0) {
        Swal.fire({
          title: "Gagal",
          text: "Item tidak boleh kosong",
          icon: "warning",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.error.items = "Item tidak boleh kosong";
      }
    },
  },

  mounted() {
    this.getVendorOption();
    this.getMedicineActive();
  },

  computed: {
    formatedSalesPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.medicine_collection_form.price = newValue.toLocaleString(
            "id-ID"
          );
        } else {
          this.medicine_collection_form.price = "";
        }
      },
      get() {
        return this.medicine_collection_form.price;
      },
    },
  },
};
</script>

<style scoped>
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>